import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"
import BlogPostLayout from "components/insights/BlogPostLayout"
import Pagination from "components/Pagination"
import MediaCard from "components/insights/MediaCard"

function BlogPostArchive({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) {
  const posts = data.allWpPost.nodes

  return (
    <BlogPostLayout
      title={"Insights"}
      description="경영에 도움되는 소식을 전해드립니다."
    >
      <div className="flex flex-row flex-wrap -mx-4">
        {!posts.length ? (
          <div>게시물이 없습니다.</div>
        ) : (
          posts.map(item => {
            return (
              <div className="w-full px-4 mb-12 last:mb-0" key={item.id}>
                <MediaCard
                  eyebrow={
                    item.categories && (
                      <div className="mb-1 flex flex-row flex-wrap -mx-1">
                        {item.categories.nodes.map((category, index) => {
                          if (!index) {
                            return (
                              <div
                                className="text-gray-500 px-1"
                                key={category.id}
                              >
                                {category.name}
                              </div>
                            )
                          }
                        })}
                      </div>
                    )
                  }
                  url={item.uri}
                  title={item.title}
                  excerpt={item.excerpt}
                  date={item.date}
                  featuredImage={
                    item.featuredImage && (
                      <GatsbyImage
                        style={{
                          position: "absolute",
                          height: "100%",
                          width: "100%",
                          inset: 0,
                          objectFit: "cover",
                        }}
                        image={
                          item.featuredImage?.node?.localFile?.childImageSharp
                            ?.gatsbyImageData
                        }
                        alt={item.featuredImage?.node?.altText || ``}
                      />
                    )
                  }
                />
              </div>
            )
          })
        )}
      </div>
      <div className="py-16">
        <div className="w-full lg:container mx-auto px-4 lg:px-8">
          <Pagination
            nextPagePath={nextPagePath}
            previousPagePath={previousPagePath}
          />
        </div>
      </div>
    </BlogPostLayout>
  )
}

export default BlogPostArchive

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        id
        uri
        date(formatString: "yyyy년 MM월 DD일", locale: "ko")
        title
        excerpt
        categories {
          nodes {
            id
            name
            slug
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
